export function additionalElements(props) {
  return {}
}

/*
  example
  return {
    product: {
      component: Product,
      props: props
    }
  }
*/
